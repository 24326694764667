* {
  box-sizing: border-box;
}

body {
  background-color: #474e5d;
  font-family: Helvetica, sans-serif;
}

/* The actual timeline (the vertical ruler) */
.new-timeline {
  position: relative;
  margin: 0 auto;
  background-color: #f9f9f9;
}

/* The actual timeline (the vertical ruler) */
.new-timeline2 {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #f9f9f9;
  text-align: center;
}

/* The actual timeline (the vertical ruler) */
.new-timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: #0391d4;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.new-container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.new-container::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -14px;
  background-color: white;
  border: 4px solid #0391d4;
  top: 50%;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.new-left {
  left: 0;
}

/* Place the container to the right */
.new-right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.new-left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 50.4%;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent rgb(3 145 212);
}

/* Add arrows to the right container (pointing left) */
.new-right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 50.3%;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent rgb(3 145 212) transparent transparent;
}

/* Fix the circle for containers on the right side */
.new-right::after {
  left: -16px;
}

/* The actual content */
.new-content {
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
  box-shadow: 0px 0px 10px 0px rgba(196, 196, 196, 0.3);
  transition: background-color 0.3s, transform 0.3s;
}

.new-content-opposite {
  padding: 20px 30px;
  position: relative;
  background-color: white;

  border-radius: 6px;
  box-shadow: 0px 0px 10px 0px rgba(196, 196, 196, 0.3);
  transition: background-color 0.3s, transform 0.3s;
}

.new-content-opposite3 {
  padding: 20px 30px;
  position: relative;
  border-radius: 6px;
}

.new-content-opposite2 {
  padding: 20px 30px;
  position: relative;
  border-radius: 6px;
  text-align: right;
}

@media screen and (max-width: 991px) {
  .new-content-opposite2 {
    padding: 0px 0px;
    position: relative;
    border-radius: 6px;
    text-align: right;
  }
}
/* The opposite content */
.new-opposite-content {
  position: absolute;
  width: 100%;
  top: 45%;
  padding: 10px 40px;
  background-color: #ffffff00;
  color: rgb(0, 0, 0);
  border-radius: 6px;
  transition: background-color 0.3s, transform 0.3s;
  /* box-shadow: 0px 0px 10px 0px rgba(196, 196, 196, 0.3); */
}

.new-left .new-opposite-content {
  left: 100%;
}

.new-right .new-opposite-content {
  right: 100%;
}

/* Hover effect */
.new-content:hover,
.new-content-opposite:hover {
  background-color: rgba(3, 145, 212, 0.1);
  transform: scale(1.05);
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 991px) {
  /* Place the timelime to the left */
  .new-timeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .new-container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .new-container::before {
    left: 60px;
    border: medium solid #0391d4;
    border-width: 10px 10px 10px 0;
    border-color: transparent #0391d4 transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .new-left::after,
  .new-right::after {
    left: 15px;
  }

  /* Make all right containers behave like the left ones */
  .new-right {
    left: 0%;
  }

  /* Make opposite content full width */
  .new-opposite-content {
    position: relative;
    width: 100%;
    left: 0;
    right: 0;
    /* margin-top: 20px; */
  }
}

@media screen and (max-width: 991px) {
  /* The actual timeline (the vertical ruler) */
  .new-left .new-opposite-content {
    left: 00%;
  }
}

@media screen and (max-width: 991px) {
  .new-content-opposite2 {
    position: relative;
    border-radius: 6px;
    text-align: left;
  }
}

@media screen and (max-width: 991px) {
  .new-content-opposite3 {
    padding: 0px 0px;

    position: relative;
    border-radius: 6px;
  }
}
