body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: inherit;
}
ul {
  list-style-type: none;
}
button {
  border: none;
}

/* font */
body {
  font-family: "Outfit Variable", sans-serif;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
}

.main-site-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
}

.main-site-heading {
  font-weight: 600;
  font-size: 2.5rem;
}

.add-todos-section {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin: 2rem 0;
}

.add-todos-meta {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.add-todos-form input {
  border-radius: 0.25rem;
  width: 90%;
  padding: 0.75rem 0.5rem;
  border: 1px solid black;
}

.add-todos-form {
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.add-todos-section button {
  padding: 0.7rem 1.34rem;
  border-radius: 99rem;
  background: #1d4ed8;
  color: white;
}

.add-todos-form button {
  border-radius: 0.5rem;
  padding: 0.75rem 1.75rem;
  margin-left: 2rem;
}

.todos-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.todo-item {
  background: #1d4ed8;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  display: flex;
  justify-content: space-between;
}

.todo-actions {
  display: flex;
  gap: 0.8rem;
  align-items: center;
}
.todo-action {
  cursor: pointer;
}

.todo-item.complete {
  background-color: green;
}

.todos-heading {
  margin: 1rem 0 0.5rem 0;
  font-size: 1.9rem;
}

.loading-body {
  display: flex;
  justify-content: space-between;
  border-radius: 0.3rem;
  padding: 0.8rem 1rem;
  background: #1d4ed8;
  height: 100%;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.loading-todo {
  height: 1.5rem;
  width: 7rem;
  border-radius: 1.3rem;
  background: rgb(232, 220, 220);
}

.loading-action {
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
}

.loading-action div {
  height: 1.5rem;
  width: 2rem;
  border-radius: 0.2rem;
  background: rgb(232, 220, 220);
}

.error-card {
  background: rgb(185 28 28);
  padding: 0.6rem 0.8rem;
  color: white;
  border-radius: 0.2rem;
  margin-bottom: 1rem;
}
