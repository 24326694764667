body {
  background-color: #f4f4f4;
  font-family: "Helvetica Neue", Arial, sans-serif;
  color: #333;
}

.resume-container {
  display: flex;
  flex-wrap: wrap; /* Added to allow wrapping */
  margin: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
}

.sidebar {
  background-color: #2c3e50;
  color: #ecf0f1;
  width: 30%;
  padding: 20px;
  box-sizing: border-box;
}

.sidebar-section {
  margin-bottom: 20px;
}

.sidebar h2 {
  border-bottom: 2px solid #2980b9;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.sidebar h3 {
  margin-top: 15px;
  margin-bottom: 5px;
}

.sidebar p,
.sidebar ul {
  margin-bottom: 10px;
}

.date {
  font-size: 0.9em;
  color: #bdc3c7;
}

.main-content {
  width: 70%;
  padding: 20px;
  box-sizing: border-box;
}

.header {
  margin-bottom: 20px;
  border-bottom: 2px solid #2980b9;
  padding-bottom: 10px;
}

.header h1 {
  font-size: 2.5em;
  margin: 0;
  color: #2c3e50;
}

.contact-info {
  font-size: 0.9em;
}

.contact-info a {
  color: #2980b9;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

.section {
  margin-bottom: 20px;
}

.section h2 {
  border-bottom: 2px solid #2980b9;
  padding-bottom: 5px;
  margin-bottom: 15px;
  color: #2c3e50;
}

.job {
  margin-bottom: 15px;
}

.job h3 {
  margin: 0;
  color: #34495e;
}

.job p {
  margin: 5px 0;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}

ul li {
  margin-bottom: 10px;
}

/* Media Query for Mobile Devices */
@media (max-width: 991px) {
  .resume-container {
    flex-direction: column;
    margin: 10px;
  }

  .header {
    order: -3; /* Reorder to be at the top */
    width: 100%;
    text-align: center;
    padding: 15px;
  }

  .contact-info {
    order: -2; /* Reorder to be at the top */
    width: 100%;
    text-align: center;
    padding: 15px;
  }

  .sidebar {
    width: 100%;
    padding: 15px;
    order: -1; /* Reorder to follow contact info */
  }

  .main-content {
    width: 100%;
    padding: 15px;
    order: 0; /* Reorder to follow sidebar */
  }

  .header h1 {
    font-size: 2em;
  }

  .section h2,
  .sidebar h2 {
    font-size: 1.5em;
  }

  .job h3 {
    font-size: 1.2em;
  }

  .sidebar-section,
  .section {
    margin-bottom: 15px;
  }
}

@media (min-width: 991px) {
  .mobile_version {
    display: none;
  }
}

@media (min-width: 991px) {
  .resume-container {
    margin-top: 120px;
  }
}

@media (max-width: 991px) {
  .desktop_version {
    display: none;
  }
}
