.data-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.data-form div {
  width: 100%;
}

.data-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.data-form input[type="text"],
.data-form textarea,
.data-form select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

.data-form input[type="submit"] {
  background-color: #04aa6d;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.data-form input[type="submit"]:hover {
  background-color: #45a049;
}

.hire-me-contact {
  background-color: #0391d4;
  color: #fff;
  padding: 0 40px;
  font-size: 16px;
  line-height: 55px;
  height: 55px;
  border: none;
  font-size: 18px;
  cursor: pointer;
  margin-top: 00px;
  border-radius: 5px;
}

.hire-me-contact:hover {
  background-color: #0391d4c2;
}

textarea {
  width: 100%;
  height: 200px;
  box-sizing: border-box;
  font-size: 16px;
}

/* ////////////// */

.thank-you-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.thank-you-page h1 {
  margin-bottom: 20px;
}

.thank-you-page p {
  margin-bottom: 10px;
}

.thank-you-page button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.thank-you-page button:hover {
  background-color: #0056b3;
}

.thank-you-email {
  text-align: center;
  margin-top: 120px;
}

.hero-image-thankyou img {
  width: 200px;
  height: auto;
}

.mt-50 {
  margin-top: 50px;
}

.mt-30-mobile {
  margin-bottom: 50px;
}
@media screen and (max-width: 991px) {
  .mt-30-mobile {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.col-lg-6 {
  position: relative;
  width: 100%;
  /* padding-right: 35px;
  padding-left: 35px; */
}

.pb-120 {
  padding-bottom: 120px;
}

.pt-70 {
  padding-top: 70px;
}

/* 
//
..
 */

.intro h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
}

.p {
  font-size: 1.1em;
  margin-bottom: 10px;
  color: #555;
  display: flex;
  align-items: center;
}

.intro p i {
  margin-right: 10px;
  color: #007bff;
}

.intro {
  color: #000; /* Make email and phone black */
  text-decoration: none;
}

.intro a {
  color: #000; /* Make email and phone black */
  text-decoration: none;
}

/* The actual timeline (the vertical ruler) */
.new-timeline-contact-page {
  /* padding-top: 30px; */
  padding-bottom: 30px;
  text-align: center;
}
.contact-word {
  margin-bottom: 0rem;
}

.footer {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
}
