/* Initial color of the navbar */
.navbar-scroll {
  background-color: #00000000;
  transition: background-color 0.3s ease;
  font-size: 16px;
  font-weight: 600;
}

/* Color of the active nav-link in initial state */
.navbar-scroll .nav-link.active {
  color: #fff !important;
}

.navbar-scroll .nav-link.active::after {
  background: #000000; /* Underline color when active in initial state */
}

.navbar-scroll .nav-link.active {
  color: #fff !important;
}

/* Media query for viewport width 700px or less */
@media (max-width: 991px) {
  .navbar-scroll {
    background-color: #fff;
  }

  .navbar-scroll .nav-link.active {
    color: rgb(4, 145, 212) !important; /* Adjust color for smaller screens */
    text-decoration: underline !important; /* Add underline to active nav-link */
    font-size: 16px;
    font-weight: 600;
  }
}

/* Media query for viewport width 700px or less */
@media (min-width: 990px) {
  .navbar-scroll .nav-link.active {
    color: #fff !important;
  }
}

/* Color of the navbar AFTER scroll */
.navbar-scrolled {
  background-color: #fff;
}

/* Color of the active nav-link after scroll */
.navbar-scrolled .nav-link.active {
  color: rgb(4, 145, 212) !important;
}

.navbar-scrolled .nav-link.active::after {
  background: rgb(
    4,
    145,
    212
  ) !important; /* Underline color when active after scroll */
}

/* When navbar is expanded */
.navbar-collapse.show {
  background-color: #fff; /* Change this to white when the hamburger menu is active */
}

/* Custom Navbar Styles */
.navbar-custom {
  padding-left: 1rem; /* Adjust as needed */
  padding-right: 1rem; /* Adjust as needed */
}

.navbar-custom .navbar-brand {
  margin-left: 1rem; /* Adjust as needed */
}

.navbar-custom .navbar-toggler {
  margin-right: 1rem; /* Adjust as needed */
}

.navbar-custom .navbar-nav {
  margin-right: 1rem; /* Adjust as needed */
}

.navbar-nav {
  padding-left: 35px !important;
}

.narbar-side {
  margin-left: 4rem !important; /* Adjust as needed */
  margin-right: 4rem !important; /* Adjust as needed */
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-xl {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
}

@media (max-width: 991.98px) {
  .mt-25 {
    margin-top: 25px;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

body {
  font-family: "Open Sans", sans-serif;
}

.navbar {
  font-family: "Open Sans", sans-serif;
}

.nav-link {
  position: relative;
  display: inline-block;
  font-family: "Open Sans", sans-serif;

  cursor: pointer;
  text-decoration: none; /* Remove default underline */
}

.nav-link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 5px;
  right: 0;
  background: currentColor; /* Use the current text color */
  transition: width 0.3s ease, right 0.3s ease;
}

.nav-link:hover::after {
  width: 100%;
  right: 0;
}

.navbar-scroll .nav-link.active::after {
  background: #fff; /* Underline color when active in initial state */
}

.navbar-scrolled .nav-link.active {
  color: rgb(4, 145, 212) !important;
}

.navbar-scrolled .nav-link.active::after {
  background: rgb(
    4,
    145,
    212
  ) !important; /* Underline color when active after scroll */
}
