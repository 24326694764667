.header_hero {
  position: relative;
  z-index: 5;
  background-position: bottom center;
  background-color: #f9f9f9;
  overflow: hidden;
  padding-top: 70px;
}

.hero-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.intro h2 {
  color: #0391d4;
  font-size: 30px;
  margin: 0;
}

.intro h1 {
  color: #333;
  font-size: 48px;
  margin: 10px 0;
}

.hire-me {
  background-color: #0391d4;
  color: #fff;
  padding: 0 40px;
  font-size: 16px;
  line-height: 55px;
  height: 55px;
  border: none;
  font-size: 18px;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 5px; /* Add this line to create rounded corners */
}
.hire-me:hover {
  background-color: #0391d4c2;
}

.hero-image img {
  width: 700px;
  height: auto;
  border-radius: 10px;
}

.header_hero_shape {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  z-index: -1;
}
.header_hero_shape::before {
  position: absolute;
  content: "";
  width: 1000%;
  height: 100%;
  background-color: #0391d4;
  -webkit-transform: skewX(20deg);
  -moz-transform: skewX(20deg);
  -ms-transform: skewX(20deg);
  -o-transform: skewX(20deg);
  transform: skewX(20deg);
  top: 0;
  left: 0;
}

.header_hero_image img {
  vertical-align: middle;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-center {
  display: flex;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.mt-50 {
  margin-top: 50px;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.custom-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 15px;
  margin-left: 15px;
}
@media screen and (max-width: 991px) {
  .custom-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 0px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) {
  .hero-content {
    flex-direction: column;
    align-items: center;
  }
  .hero-image img {
    width: 400px;
    /* max-width: 100%; */
  }
}
